.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
}

p a {
  margin-left: 1ch; /* Add margin to the left of the anchor tag */
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

.left {
  flex: 1;
  padding: 20px;
}

.right {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.about .right img {
  max-width: 100%;
  max-height: 100%;
}

.about {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 60px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about .prompt {
  width: 80%;
  font-size: 20px;
  text-align: center;
}
.prompt svg {
  font-size: 60px;
  margin: 5px;
}

.skills {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1 {
  color: #3e497a;
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list {
  padding: 0;
}

.skills h2 {
  font-size: 30px;
}

.list span {
  font-size: 20px;
}

.workexpr {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.workexpr h1 {
  color: #3e497a;
}

@media only screen and (max-width: 1250px) {
  .about {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 875px) {
  .about {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 30px;
    height: 30px;
  }
}
