.experience {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.experience .experienceTitle {
  text-align: center;
  color: #3e497a;
  font-size: 60px;
  font-weight: bolder;
}

.experienceList {
  height: auto;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}

/* MENU ITEM STYLING */

.experienceItem {
  border-radius: 15px;
  width: 400px;
  height: 600px;
  margin: 40px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  padding: 20px;
  background-color: white;
}
.experienceItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.experienceItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 225px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.experienceItem h1 {
  font-size: 25px;
}

.link-container {
  display: flex;
  gap: 10px; /* Adjust this value to control the spacing between the links */
  justify-content: center; /* Horizontally center the buttons */
  align-items: center; /* Vertically center the buttons */
  color: #3e497a;
}

.link-container a {
  text-decoration: none;
  display: block;
  padding: 5px 10px; /* Add padding around the links */
  border: 1px solid #ccc; /* Add a border for visual separation */
  border-radius: 4px; /* Rounded corners for the links */
  color: #3e497a;
}

@media only screen and (max-width: 1000px) {
  .experienceList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .experienceList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .experienceList {
    width: 100%;
  }
  .experienceItem {
    border-radius: 15px;
    width: 300px;
    height: 650px;
    margin: 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
}
