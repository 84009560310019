.navbar {
  width: 100%;
  height: 100px;
  background: #21325e;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 20px;
  font-size: 25px;
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}

.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

/* Default styles */
/* #open {
  height: 100vh;
} */

/* 
#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
} */

#open a {
  width: 100%;
  text-align: center;
}

.toggleButton {
  display: none;
  /* Default hidden */
}

/* <1000px */
@media screen and (max-width: 1000px) {
  .navbar a {
    width: 70px;
  }

  .navbar {
    height: 100px;
  }

  .toggleButton {
    display: block;
  }

  /* #open .links {
    display: none;
  } */

  .links {
    /* display: none; */
    flex-direction: column;
  }

  #open.navbar {
    height: 100vh;
  }

  #close .links {
    display: none;
  }

}